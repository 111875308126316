<template>
  <section class="about__page" style="height: 100%;">
    <div class="container d-flex justify-start align-center">
      <Breadcrumb class="territorio_section_bc-color">
        <template v-slot:breadcrumbLinks>
          <router-link
            tag="p"
            :to="{ name: link.href }"
            v-for="(link, idx) in breadcrumbItems"
            :key="idx"
          >
            {{ link.text }}
          </router-link>
        </template>
      </Breadcrumb>
    </div>

    <div class="container">
      <v-row
        v-if="this.$route != 'mapa_territorios'"
        class="d-flex justify-md-center align-center pt-5 pb-5 ovx"
      >
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'sobre_camargos' }">
            Sobre
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'sobre_nucleo_urbano_camargos' }">
            Configuração do Núcleo Urbano
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'sobre_cultura_camargos' }">
            Cultura
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'sobre_rompimento_camargos' }">
            O rompimento
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'mapa_territorios' }">
            Mapa
          </router-link>
        </v-col>
      </v-row>
    </div>

    <transition>
      <router-view />
    </transition>
    <ScrollTop />
  </section>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";
import ScrollTop from "@/components/ScrollTop.vue";

export default {
  components: { Breadcrumb, ScrollTop },
  data() {
    return {
      active: "active",
      breadcrumbItems: [
        {
          text: "< Territórios /",
          href: "mapa_territorios",
        },
        {
          text: "Camargos",
          href: "sobre_camargos",
        },
      ],
    };
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .ovx {
    overflow-y: scroll;
    flex-wrap: nowrap;
    margin-bottom: 2em;
  }

  .ovx::-webkit-scrollbar {
    width: 10px;
  }
  .ovx::-webkit-scrollbar-track {
    box-shadow: inset 0 1px rgb(224, 105, 25, 0.6);
    border: 6px solid transparent;
    background-clip: content-box;
    width: 180px;
  }
  .ovx::-webkit-scrollbar-thumb {
    background: rgb(224, 105, 25, 0.8);
    border-radius: 10px;
    height: 4px;
    width: 180px;
  }
}
.territorio_nav {
  margin-bottom: 2em;
}

a {
  color: #5d3f36;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.8em;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #e06919;
}

.router-link {
  color: #5d3f36;
}
</style>
